import React, {
  useEffect,
  useState,
} from "react"

import axios from "axios"
import { Link } from "gatsby"

import { ArrowLeftIcon } from "@heroicons/react/solid"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HowToHelp from "../../components/shared/how-to-help"
import InnerBanner from "../../components/shared/inner-banner"
import { API_BASE_URL } from "../../constants/config"
import banner from "../../images/resources-banner-bg.jpg"
import * as styles from "./event-details.module.css"

const EventDetails = ({ params }) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        var m = true;
        if (m) {
            console.log(params);
            axios.get(API_BASE_URL + "/website/events/show/" + params.key).then(({ data }) => {
                setData(data.data);
            })
        }

        return () => {
            m = false;
        }

    }, [params])

    return <Layout colored_header={true}>
        <Seo title={data ? data.title : "Events"} />
        <InnerBanner theme="white" image={banner} title="Events" />
       
        {data && <div className={styles.container}>
            <div className={styles.main_content}>
                 <Link className={styles.btn_back} to="/events/"><ArrowLeftIcon className="w-4 h-4"/>Back to Events</Link>
                <div className={styles.inner_content}>

                    {/* {data.featured_image && <div className={styles.thumb}>
<img src={data.featured_image ? API_BASE_URL.replace("/v1", "/") + data.featured_image.path : defaultDog} alt={data.title} />
                </div>} */}
                <div className={styles.content}>
                    <h2>{data.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                </div>
                </div>

            </div>
        </div>}
        <HowToHelp />
    </Layout>
}
export default EventDetails;